import { configureStore, ThunkAction, Action, AnyAction, Middleware, Dispatch } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import rootReducer from '../features/rootSlice';
import logger from 'redux-logger';
import { chatMiddleware } from '../features/communication/CommunicationSlice';

const loggerMiddleware = logger as Middleware<{}, any, Dispatch<AnyAction>>

export const store = configureStore({
  reducer: rootReducer,
  middleware:
    (getDefaultMiddleware) => {
      const middlewares = getDefaultMiddleware({ serializableCheck: false })
      middlewares.push(chatMiddleware())
      if (process.env.NODE_ENV !== 'production')
        middlewares.push(loggerMiddleware)

      return middlewares
    }
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
