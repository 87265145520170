import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { DownloadStatus, removeDownloadStatusEntry, setDownloadStatus } from "./ProjectsSlice";
import { generateFileDownloadLink, ProjectEntity } from "./ProjectsAPI";
import { Directory, Filesystem, ProgressListener } from "@capacitor/filesystem";
import { Media } from "@capacitor-community/media";
import { isIOS, isMedia, isVideo } from "../../app/utils";


export async function nativeDownloadFile(
  repoId: string, folder: string, filename: string, sessionToken: string,
  getCurrentStatus: () => Promise<DownloadStatus | undefined>, location: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>, path: string,
  directory: Directory, originalRepoId: string, originalFolder: string, file: ProjectEntity
) {
  const downloadUrl: string = await generateFileDownloadLink(
    repoId, folder, filename, sessionToken
  );

  try {
    const [signal, downloadProgressHandler] = handleDownloadProgress(
      getCurrentStatus,
      dispatch,
      repoId,
      folder,
      filename,
      downloadUrl
    );
    await filesystemDownload(downloadProgressHandler, path, downloadUrl, directory, signal)
    if (isMedia(filename)) await storeAsMedia(filename, path, directory);
    dispatch(removeDownloadStatusEntry(location));
  } catch (error) {
    await handleNativeDownloadFileError(
      getCurrentStatus, dispatch, location, repoId, folder,
      filename, originalRepoId, originalFolder, file, error
    );
    throw error;
  }
}

async function filesystemDownload(
  downloadProgressHandler: ProgressListener,
  path: string,
  downloadUrl: string,
  directory: Directory,
  signal?: AbortSignal
) {
  const listener = await Filesystem.addListener(
    "progress",
    downloadProgressHandler
  );
  await Filesystem.downloadFile({
    path,
    url: downloadUrl,
    directory,
    progress: true,
    readTimeout: 7000,
  });
  await listener.remove();
}

async function handleNativeDownloadFileError(
  getCurrentStatus: () => Promise<DownloadStatus | undefined>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  location: string, repoId: string, folder: string,
  filename: string, originalRepoId: string, originalFolder: string,
  file: ProjectEntity, error: unknown
) {
  const status = await getCurrentStatus();
  if (status?.state === 'canceled') {
    dispatch(removeDownloadStatusEntry(location));
  } else {
    dispatch(setDownloadStatus({
      repoId,
      folder,
      filename,
      status: failedStatus(originalRepoId, originalFolder, file, error as Error)
    }));
  }
}

function handleDownloadProgress(
  getCurrentStatus: () => Promise<DownloadStatus | undefined>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  repoId: string,
  folder: string,
  filename: string,
  downloadUrl: string
): [AbortSignal | undefined, ProgressListener] {
  const controller = AbortController ? new AbortController() : undefined;
  return [
    controller?.signal,
    async (event) => {
      if (event.url === downloadUrl) {
        const status = await getCurrentStatus();
        if (status?.state !== 'canceled') {
          dispatch(setDownloadStatus({
            repoId,
            folder,
            filename,
            status: {
              downloadedBytes: event.bytes,
              totalBytes: event.contentLength,
              state: 'downloading'
            }
          }));
        } else {
          controller?.abort()
        }
      }
    }
  ];
}

function failedStatus(
  repo: string, folder: string, entity: ProjectEntity, error: Error
): DownloadStatus {
  return {
    downloadedBytes: 0,
    totalBytes: 0,
    error: error as Error,
    state: 'canceled',
    data: {
      repo,
      folder,
      entity
    }
  }
}

async function createAlbum(name: string) {
  await Media.createAlbum({name: name});
  const { albums } = await Media.getAlbums();
  return albums?.find(x => x.name === name);
}

async function getAlbumIdentifier(name: string): Promise<string|undefined> {
  const { albums } = await Media.getAlbums();
  let albumIdentifier: string|undefined = albums?.find(x => x.name === name)?.identifier;
  if (!albumIdentifier) {
    const album = await createAlbum(name);
    albumIdentifier = album?.identifier;
  }
  return albumIdentifier;
}

async function storeAsMedia(filename: string, path: string, directory: Directory){
  const storedFile = await Filesystem.stat({path, directory});
  const options = {
    path: storedFile?.uri,
    albumIdentifier: isIOS() ? undefined : await getAlbumIdentifier('Meraki')
  };
  if (isVideo(filename)) {
    await Media.saveVideo(options);
  } else {
    await Media.savePhoto(options);
  }
  await Filesystem.deleteFile({
    path: storedFile?.uri
  });
}