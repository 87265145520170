import { io, Socket } from "socket.io-client";
import { simpleArrayHash } from "../../app/utils";
import configuration from "../configuration/Configuration";

type ChatUser = {
  reference: string;
  name: string;
}

export type ChatMessage = {
  content: string;
}
export type ChatMessageRequest = ChatMessage & {
  attachment?: string;
}
export type ReceivedChatMessage = ChatMessage & {
  id: string;
  timestamp: number;
  from?: ChatUser;
  room: string[] | string;
  metadata?: {
    firstOfRoom?: boolean;
    attachment?: string | ArrayBuffer;
    whatsapp_chat_id?: string
    whatsapp_message_id?: string
    edited?: boolean
  },
  stub?: boolean
}
export type StoredReceivedChatMessage = ReceivedChatMessage & {
  checked?: boolean
}

export type RoomId = string;
export type ServerRoomId = string | string[];

export type ServerRoom = {
  id: ServerRoomId;
  lastUpdateTimestamp?: number;
}
export type Room = {
  id: RoomId;
  lastUpdateTimestamp?: number;
}

type MessagesSet = StoredReceivedChatMessage[];
function addToMessagesSet(set: MessagesSet, newElement: StoredReceivedChatMessage){
  // Controllo che il messaggio non sia già arrivato
  const found = set.find(element => (element.id === newElement.id));
  
  let updatedSet = set
  if(!found){
    // Rimozione messaggio di stub se c'è
    updatedSet =
      updatedSet.filter(
        element =>
          element.stub !== true ||
          element.content !== newElement.content ||
          element.metadata?.attachment !== newElement.metadata?.attachment
      );

    updatedSet.push(newElement);
    updatedSet.sort((a, b) => a.timestamp - b.timestamp);
  }
  return updatedSet;
}

export type MessagesStorage = Record<RoomId, MessagesSet>;
export const EMPTY_LIST: ReadonlyArray<Readonly<MessagesSet>> = [];
export function addMessage(store: MessagesStorage, message: StoredReceivedChatMessage) {
  const roomId = RoomManager.generateRoomId(message.room);
  if(!store[roomId]) store[roomId] = [];
  message.timestamp *= 1000;
  return {
    ...store,
    [roomId]: addToMessagesSet(store[roomId], message)
  };
}
export function getLastMessage(store: MessagesStorage, room: ServerRoomId) {
  const roomId = RoomManager.generateRoomId(room);
  const list = store[roomId] || [];
  return list.reduce<StoredReceivedChatMessage | undefined>(
    (last, current) =>
      (last?.timestamp || 0) < current.timestamp ? current : last,
    undefined
  )
}

export class RoomManager {
  static virtualGroupsMap: Record<string, string[]> = {};
  static generateRoomId(recipient: ServerRoomId): RoomId{
    if(recipient instanceof Array){
      const vgroupId = simpleArrayHash(recipient);
      RoomManager.virtualGroupsMap[vgroupId] = recipient;
      return `users:${vgroupId}`;
    }else
      return recipient;
  }
  static extractFromRoomId(roomId: RoomId): ServerRoomId | undefined {
    if(roomId.startsWith('users:')){
      const vgroupId = roomId.substring(6);
      return RoomManager.virtualGroupsMap[vgroupId];
    }else
      return roomId;
  }
}

export async function openChatConnection(
  sessionToken: string, connectHandle: () => any,
  disconnectHandle: () => any
): Promise<Socket>{
  const socket = io(configuration.chatsWsHost, {
    auth: { token: sessionToken },
    path: configuration.chatsWsPath
  });
  return new Promise((resolve, reject) => {
    const errCallback = (error: Error) => {
      socket.disconnect();
      reject(new Error(`Impossibile connettersi al server chat: ${error.message}`));
    }
    socket.on("connect", () => {
      resolve(socket);
      connectHandle();
    });
    socket.on("disconnect", () => {
      setTimeout(
        () => disconnectHandle(),
        500
      )
    });
    socket.on('connect_failed', errCallback);
    socket.on('connect_error', errCallback);
    socket.on('error', errCallback);
  })
}

export function sendChatMessage(
  room: ServerRoomId, message: ChatMessageRequest, connection: Socket
): void {
  let metadata;
  if(message.attachment){
    metadata = {
      attachment: message.attachment
    };
  }
  connection.emit('send-message', {
    room,
    content: message.content,
    metadata
  });
}

export function fetchChatMessages(
  fromDate: number,
  limit: number,
  roomId: RoomId,
  connection: Socket,
  bufferIfConnectionNotRedy?: boolean,
): void{
  const room = RoomManager.extractFromRoomId(roomId);
  const messagesRequest = {
    room,
    from_date: Math.floor(fromDate / 1000),
    limit
  }
  console.debug("Requested messages", messagesRequest)
  const connectionPoint = bufferIfConnectionNotRedy ? connection : connection?.volatile
  connectionPoint?.emit('get-messages', messagesRequest);
}

export const addToWhatsappGroup = async (
  sessionToken: string, telephone: string, roomId: RoomId
): Promise<any | null> => {
  const host = configuration.chatsWsHost;
  const serverRoomId = RoomManager.extractFromRoomId(roomId);
  if (!serverRoomId || serverRoomId instanceof Array) return null
  try {
    const extResponse = await fetch(
      `${host}/whatsapp/group`,
      {
        method: 'PUT',
        cache: 'no-cache',
        headers: {
          'Authorization': `Token ${sessionToken}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        body: JSON.stringify({
          telephone: telephone,
          room: serverRoomId
        })
      }
    )
    const body = await extResponse?.json()
    if (!extResponse.ok)
      throw new Error(body)
    return body
  } catch(e) {
    console.error(e)
    return null
  };
}
