import { downloadLink, generateDocumentViewer, isDocumentFile, isVideo, isImage } from "../../app/utils";
import APIError from "../api/APIError";
import { handleFetchError } from "../api/request";
import configuration from "../configuration/Configuration"

const HIDE_USERS = ['amministratore@meraki.it', 'admin@meraki.it'];

export type RepoId = string;

type ProjectId = RepoId;

type FolderId = RepoId;

type ProjectFolder = {
  folder: string;
  repo_id: FolderId;
}

export type Project = {
  id: ProjectId;
  name: string;
  folders: ProjectFolder[];
}

export const loadMine = async (sessionToken: string): Promise<Project[]> => {
  const host = configuration.seafileExtApiHost;
  const extResponse = await fetch(
    `${host}/libraries/user`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!extResponse.ok)
    throw await APIError.build(extResponse);
  const projects = await extResponse.json();
  return projects;
}

type PersonalFolderQueryResponse = {
  repo_id: RepoId;
  exists: boolean;
};

export const getPersonalLibrary = async (sessionToken: string): Promise<RepoId> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api2/default-repo/`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok)
    throw await APIError.build(response);
  const result: PersonalFolderQueryResponse = await response.json();
  if(!result.exists)
    throw new Error("Cartella personale non disponibile");
  return result.repo_id;
}

export type ProjectMember = {
  user_info: { nickname: string; name: string },
  share_type: string;
  permission: "r" | "rw" | "w"
}

export const loadProjectMembers = async (
  folderIds: FolderId[], sessionToken: string
): Promise<ProjectMember[]> => {
  const host = configuration.seafileHost;
  const members: ProjectMember[] = [];
  for(const repoId of folderIds){
    const response = await fetch(
      `${host}/api2/repos/${repoId}/dir/shared_items/?p=/&share_type=user`,
      {
        method: 'GET',
        cache: 'no-cache',
        headers: {
          'Authorization': `Token ${sessionToken}`,
        },
        redirect: 'follow',
      }
    ).catch(handleFetchError);
    if(!response.ok){
      if(response.status === 404)
        throw new Error("Progetto non trovato");
      else
        throw await APIError.build(response);
    }
    const responseContent: ProjectMember[] = await response.json();
    for(const retrieved of responseContent)
      if(
        !members.find(
          member => member.user_info.name === retrieved.user_info.name
        ) &&
        !HIDE_USERS.includes(retrieved.user_info.name)
      )
        members.push(retrieved);
  }
  return members;
}

export type ProjectFile = {
  id: string;
  type: "file";
  name: string;
  size: number;
  mtime: number;
}
export type ProjectDir = {
  id: string;
  type: "dir";
  name: string;
  mtime: number;
}
export type ProjectEntity = ProjectFile | ProjectDir;

export const loadRepo = async (
  repoId: RepoId, folder: string, sessionToken: string
): Promise<ProjectEntity[]> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api2/repos/${repoId}/dir/?p=${encodeURIComponent(folder)}`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
        'Accept': 'application/json'
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok){
    if(response.status === 404)
      throw new Error("Sezione progetto non trovata");
    else
      throw await APIError.build(response);
  }
  const responseContent: ProjectEntity[] = await response.json();
  const withoutThumbnails = responseContent.filter(
    entity => !entity.name.endsWith('.tmb')
  ).sort((a, b) => b.mtime - a.mtime);
  return withoutThumbnails;
}

type DownloadLink = string;

export const generateFileDownloadLink = async (
  repoId: RepoId, folder: string, filename: string, sessionToken: string
): Promise<DownloadLink> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api2/repos/${repoId}/file/?p=${encodeURIComponent(folder)}/${encodeURIComponent(filename)}&reuse=1`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok){
    if(response.status === 404)
      throw new Error("File non trovato");
    else if(response.status === 400)
      throw new Error("La sezione indicata non appartiene al progetto");
    else
      throw await APIError.build(response);
  }
  const downloadUrl: string = await response.json();
  return downloadUrl;
}

type PreviewAPIResponse = {
  url: string
  thumbnail?: string
}

export const getFilePreview = async (
  repoId: RepoId, folder: string, file: ProjectEntity, sessionToken: string, forceCreation: boolean = true
): Promise<PreviewAPIResponse> => {
  const host = configuration.seafileExtApiHost;
  let url = `${host}/video/${repoId}/${encodeURIComponent(folder)}/${encodeURIComponent(file.name)}?createIfNotExists=${forceCreation}`
  if (file.mtime)
    url += `&mtime=${file.mtime}`
  const response = await fetch(
    url,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok){
    if(response.status === 404)
      throw new Error("File non trovato");
    else if(response.status === 400)
      throw new Error("La sezione indicata non appartiene al progetto");
    else
      throw await APIError.build(response);
  }
  const apiResponse: PreviewAPIResponse = await response.json();
  return apiResponse;
}

type OWAResponse = {
  access_token: string;
  action_url: string;
  access_token_ttl: number
}

export const generateFileViewerLink = async (
  folderId: FolderId, filename: string, sessionToken: string
): Promise<string> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api2/repos/${folderId}/owa-file/?p=/${encodeURIComponent(filename)}&action=view`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok)
    throw await APIError.build(response);
  const owaResponse: OWAResponse = await response.json();
  return owaResponse.action_url;
}

type ShareLinkReponse = {
  link: string;
  is_expired: boolean;
}

export const generateShareLink = async (
  repo: RepoId, folder: string, filename: string, sessionToken: string
): Promise<string> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api/v2.1/share-links/`,
    {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow',
      body: `repo_id=${encodeURIComponent(repo)}` +
            `&path=${encodeURIComponent(folder)}/${encodeURIComponent(filename)}` +
            `&can_download=true`
    }
  ).catch(handleFetchError);
  if(!response.ok){
    if(response.status === 404)
      throw new Error("File, sezione o progetto non trovati");
    else if(response.status === 400)
      throw new Error("Percorso file non trovato");
    else
      throw await APIError.build(response);
  }
  const parsedResponse: ShareLinkReponse = await response.json();
  return parsedResponse.link;
}

export const getShareLinks = async (
  repo: RepoId, folder: string, filename: string, sessionToken: string
): Promise<string[]> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api/v2.1/share-links/?repo_id=${repo}&path=${encodeURIComponent(folder)}/${encodeURIComponent(filename)}`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok){
    if(response.status === 404)
      throw new Error("File, sezione o progetto non trovati");
    else
      throw await APIError.build(response);
  }
  const parsedResponse: ShareLinkReponse[] = await response.json();
  return parsedResponse.map( response => response.link);
}

export const getOpenLink = async (
  repoId: RepoId, folder: string, filename: string, sessionToken: string
): Promise<string> => {
  let viewUrl;
  if(isDocumentFile(filename)){
    try{
      const downloadUrl: string = await generateFileDownloadLink(
        repoId, folder, filename, sessionToken
      );
      viewUrl = generateDocumentViewer(downloadUrl);
    }catch{}
  }
  if(!viewUrl){
    const links: string[] = await getShareLinks(
      repoId, folder, filename, sessionToken
    );
    if(links.length > 0){
      viewUrl = links[0];
    }else{
      viewUrl = await generateShareLink(
        repoId, folder, filename, sessionToken
      );
    }
  }
  return viewUrl;
}

export const deleteFile = async (
  repoId: RepoId, folder: string, filename: string, sessionToken: string
): Promise<void> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api2/repos/${repoId}/file/?p=${encodeURIComponent(folder)}/${encodeURIComponent(filename)}`,
    {
      method: 'DELETE',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
        'Accept': 'application/json',
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok){
    if(response.status === 400)
      throw new Error("Percorso file non trovato");
    else
      throw await APIError.build(response);
  }
  if(isVideo(filename) || isImage(filename)){
    const filenameSlices = filename.split('.');
    try{
      await deleteFile(
        repoId, folder,
        `${filenameSlices.slice(0, -1).join('.')}_${filenameSlices[filenameSlices.length - 1]}.256.tmb`,
        sessionToken
      );
    }catch{}
    if(isImage(filename)){
      try{
        await deleteFile(
          repoId, folder,
          `${filenameSlices.slice(0, -1).join('.')}_${filenameSlices[filenameSlices.length - 1]}.1280.tmb`,
          sessionToken
        );
      }catch{}
    }
    try{
      await deleteFile(
        repoId, folder,
        `${filenameSlices.slice(0, -1).join('.')}_${filenameSlices[filenameSlices.length - 1]}.tmb`,
        sessionToken
      );
    }catch{}
    try{
      await deleteFile(
        repoId, folder,
        `${filenameSlices.slice(0, -1).join('.')}.tmb`,
        sessionToken
      );
    }catch{}
  }
}

export const renameFile = async (
  repoId: RepoId, folder: string, filename: string, newFileName: string,
  sessionToken: string
): Promise<void> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api2/repos/${repoId}/file/?p=${encodeURIComponent(folder)}/${encodeURIComponent(filename)}`,
    {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'manual',
      body: `operation=rename&newname=${encodeURIComponent(newFileName.toLowerCase())}`
    }
  ).catch(handleFetchError);
  if(!response.ok){
    if(response.type !== 'opaqueredirect'){
      if(response.status === 400)
        throw new Error("Percorso file non trovato");
      else
        throw await APIError.build(response);
    }
  }
}

export type ParsingType = "json" | "blob";

export type DownloadOptions = {
  getInApp?: boolean;
  parseResponse?: ParsingType;
}

export async function getFile(url: string, sessionToken: string, parsing?: ParsingType){
  const response = await fetch(
    url,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok){
    throw await APIError.build(response);
  }
  const objectResponse: any =
    parsing === 'json' ? await response.json() : await response.blob();
  return objectResponse;
}

export async function getFileDetails(
  folderId: FolderId, fileId: string, sessionToken: string
): Promise<ProjectEntity>{
  const folderContent = await loadRepo(folderId, '/', sessionToken);
  const file = folderContent.find(entity => entity.id === fileId);
  if(!file)
    throw new Error("File non trovato");
  return file;
}

export const downloadFile = async (
  repoId: RepoId, folder: string, filename: string, sessionToken: string,
  options?: DownloadOptions
): Promise<any> => {
  const downloadUrl: string = await generateFileDownloadLink(
    repoId, folder, filename, sessionToken
  );
  if(!options?.getInApp)
    return await downloadLink(downloadUrl, filename)
        .then(res => null)
        .catch(err => {
            throw new Error(`Impossibile scaricare il file ${filename}`);
        });
  else
    return await getFile(downloadUrl, sessionToken, options?.parseResponse);
}

type UploadLink = string;

export const generateFileUploadLink = async (
  folderId: FolderId, sessionToken: string
): Promise<UploadLink> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api2/repos/${folderId}/upload-link/?p=/`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok){
    if(response.status === 403)
      throw new Error("Non hai il permesso di caricare file qui");
    else if(response.status === 500)
      throw new Error("Hai esaurito lo spazio utilizzabile per i tuoi caricamenti");
    else
      throw await APIError.build(response);
  }
  const uploadUrl: string = await response.json();
  return uploadUrl;
}

export type UploadOptions = {
  toReplace?: ProjectEntity;
}

export type UploadedFile = {
  name: string;
  id: string;
  size: number;
}

export const uploadFile = async (
  repoId: RepoId, folder: string, file: File, sessionToken: string,
  options?: UploadOptions
): Promise<UploadedFile[]> => {
  const uploadLink: UploadLink = await generateFileUploadLink(repoId, sessionToken);
  const formData = new FormData();
  const filename = options?.toReplace?.name || file.name;
  formData.set('file', file, filename.toLowerCase());
  formData.set('parent_dir', folder);
  formData.set('replace', Boolean(options?.toReplace) ? '1' : '0');
  const response = await fetch(
    `${uploadLink}?ret-json=1`,
    {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`
      },
      redirect: 'follow',
      body: formData
    }
  ).catch(handleFetchError);
  if(!response.ok){
    if(response.status === 403)
      throw new Error("Non hai il permesso di caricare file qui");
    else if(response.status === 440)
      throw new Error("Nome file non valido");
    else if(response.status === 442)
      throw new Error("Il file è troppo grande");
    else if(response.status === 500)
      throw new Error("Hai esaurito lo spazio utilizzabile per i tuoi caricamenti");
    else
      throw await APIError.build(response);
  }
  const details = await response.json() as UploadedFile[];
  return details;
}

type ChunkUploadResult = {
  uploadLink: UploadLink;
}

export type ChunkUploadOptions = UploadOptions & {
  uploadLink?: UploadLink;
  controller?: AbortController
}

export const uploadChunk = async (
  repoId: RepoId, folder: string, chunk: Blob, filename: string, current: number,
  total: number, sessionToken: string, options?: ChunkUploadOptions
): Promise<UploadedFile[] | ChunkUploadResult> => {
  filename = encodeURIComponent(filename.toLowerCase());
  const uploadLink: UploadLink =
    options?.uploadLink ||
    await generateFileUploadLink(repoId, sessionToken);
  const end = current + chunk.size - 1;
  const formData = new FormData();
  formData.set('file', chunk);
  formData.set('parent_dir', folder);
  formData.set('replace', Boolean(options?.toReplace) ? '1' : '0');
  const response = await fetch(
    `${uploadLink}?ret-json=1`,
    {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
        'Content-Range': `bytes ${current}-${end}/${total}`,
        'Content-Disposition': `attachment; filename="${filename}"`
      },
      redirect: 'follow',
      body: formData,
      signal: options?.controller?.signal
    }
  ).catch(handleFetchError);
  if(!response.ok){
    if(response.status === 403)
      throw new Error("Non hai il permesso di caricare file qui");
    else if(response.status === 440)
      throw new Error("Nome file non valido");
    else if(response.status === 442)
      throw new Error("Il file è troppo grande");
    else if(response.status === 500)
      throw new Error("Hai esaurito lo spazio utilizzabile per i tuoi caricamenti");
    else
      throw await APIError.build(response);
  }
  const details = end === (total - 1) ?
    await response.json() as UploadedFile[] :
    { uploadLink };
  return details;
}

export const getFileCustomThumbnail = async (
  repoId: RepoId, folder: string, file: ProjectEntity, sessionToken: string, size: number
): Promise<string | undefined> => {
  const filenameSlices = file.name.split('.');
  const thumbnailName1 = `${filenameSlices.slice(0, -1).join('.')}.tmb`;
  const fileExtension = filenameSlices[filenameSlices.length - 1];
  const thumbnailName2 =
    `${filenameSlices.slice(0, -1).join('.')}_${fileExtension}.tmb`;
  const thumbnailName3 = `${filenameSlices.slice(0, -1).join('.')}_${fileExtension}.${size}.tmb`;
  let thumbnail = undefined;
  try{
    try{
      thumbnail = await generateFileDownloadLink(
        repoId, folder, thumbnailName3, sessionToken
      );
    }catch{
      if(size === 256 && file.mtime < 1676505600){
        try{
          thumbnail = await generateFileDownloadLink(
            repoId, folder, thumbnailName2, sessionToken
          );
        } catch {
          thumbnail = await generateFileDownloadLink(
            repoId, folder, thumbnailName1, sessionToken
          );
        }
      }
    }
  }catch{
    thumbnail = undefined;
  }
  return thumbnail;
}

export type ThumbnailOptions = {
  size?: number;
}

export const getFileThumbnail = async (
  repoId: RepoId, folder: string, file: ProjectEntity, sessionToken: string,
  thumbnailOptions?: ThumbnailOptions
): Promise<{ url: string | null, cachable: boolean }> => {
  try{
    const customThumbnail = await getFileCustomThumbnail(
      repoId, folder, file, sessionToken, thumbnailOptions?.size || 256
    );
    if(customThumbnail) return { url: customThumbnail, cachable: true };
  }catch{}
  const host = configuration.seafileHost;
  return {
    url: `${host}/api2/repos/${repoId}/thumbnail/?p=${encodeURIComponent(folder)}/${encodeURIComponent(file.name)}&size=${thumbnailOptions?.size || 256}`,
    cachable: false
  };
}

export const setCustomThumbnailToFile = async (
  repoId: RepoId, folder: string, filename: string, thumbnail: Blob,
  size: number, sessionToken: string, options?: UploadOptions
): Promise<void> => {
  const filenameSlices = filename.split('.');
  const fileExtension = filenameSlices[filenameSlices.length - 1];
  const thumbnailName =
    `${filenameSlices.slice(0, -1).join('.')}_${fileExtension}.${size}.tmb`;
  const file = new File([thumbnail], thumbnailName);
  let thumbnailOptions: UploadOptions = {};
  if(options?.toReplace){
    thumbnailOptions.toReplace = {...options.toReplace};
    thumbnailOptions.toReplace.name = thumbnailName;
  }
  await uploadFile(repoId, folder, file, sessionToken, thumbnailOptions);
}
