import APIError from "../api/APIError";
import { handleFetchError } from "../api/request";
import configuration from "../configuration/Configuration"

export type LoginResponse = { token: string }

export const login = async (email: string, password: string): Promise<LoginResponse> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api2/auth-token/`,
    {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow',
      body: `username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`
    }
  ).catch(handleFetchError);
  if(!response.ok){
    if(response.status === 400){
      const errorResponse: { non_field_errors: string[] } = await response.json();
      throw new Error(
        errorResponse.non_field_errors?.length > 0 ?
        errorResponse.non_field_errors[0] :
        response.statusText
      );
    }
    throw await APIError.build(response);
  }
  const responseContent: LoginResponse = await response.json();
  return responseContent;
}

export type UserDetails = {
  telephone?: string
  login_id: string;
  name: string;
  contact_email: string;
  email: string;
}

export const getMe = async (sessionToken: string): Promise<UserDetails> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api/v2.1/user/`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
        'Accept': 'application/json; indent=4',
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok)
    throw await APIError.build(response);
  const responseContent: UserDetails = await response.json();
  return responseContent;
}

export const getUser = async (
  email: string, sessionToken: string
): Promise<UserDetails> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api2/search-user/?q=${email}`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
        'Accept': 'application/json; indent=4',
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok)
    throw new Error(response.statusText);
  const responseContent: { users: UserDetails[] } = await response.json();
  const user = responseContent.users.find(user => user.email === email);
  if(!user)
    throw new Error("Utente non trovato")
  return user;
}

type EditableUserFields = Omit<Partial<UserDetails>, 'email'> & {
  reference_id?: string;
  password?: string;
}

export const editMe = async (
  me: EditableUserFields, myEmail: string, sessionToken: string
): Promise<UserDetails> => {
  const host = configuration.seafileExtApiHost;
  const changes: string[] = [];
  for(const [field, value] of Object.entries(me)){
    changes.push(`${field}=${encodeURIComponent(value)}`);
  }
  const response = await fetch(
    `${host}/user/${myEmail}/update`,
    {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Token ${sessionToken}`,
        'Accept': 'application/json; indent=4',
      },
      redirect: 'follow',
      body: changes.join('&')
    }
  ).catch(handleFetchError);
  if(!response.ok)
    throw await APIError.build(response);
  const responseContent: UserDetails = await response.json();
  return responseContent;
}

export const editMyPhone = async (
  telephone: string, myEmail: string, sessionToken: string
): Promise<UserDetails> => {
  return await editMe({ telephone }, myEmail, sessionToken);
}

export const editMyEmail = async (
  email: string, myEmail: string, sessionToken: string
): Promise<UserDetails> => {
  return await editMe({ reference_id: email }, myEmail, sessionToken);
}

export const sendRecoverEmail = async (email: string): Promise<void> => {
  const host = configuration.seafileExtApiHost;
  const response = await fetch(
    `${host}/user/${email}/reset`,
    {
      method: 'GET',
      cache: 'no-cache',
      redirect: 'follow'
    }
  ).catch(handleFetchError);
  if(!response.ok)
    throw await APIError.build(response);
}

export const changePassword = async (
  newPassword: string, myEmail: string, sessionToken: string
): Promise<UserDetails> => {
  return await editMe({ password: newPassword }, myEmail, sessionToken);
}

export type ServerStatus = "up" | "down" | "unknown";

export const getStatus = async (sessionToken: string): Promise<ServerStatus> => {
  const host = configuration.seafileHost;
  const response = await fetch(
    `${host}/api2/auth/ping/`,
    {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Token ${sessionToken}`,
      },
      redirect: 'follow',
    }
  ).catch(handleFetchError);
  if(!response.ok)
    throw await APIError.build(response);
  const result: string = await response.json();
  return result === "pong" ? "up" : "down";
}

export function phoneNumbersToPhoneString(numbers: string[]) {
  return numbers.join(";").replaceAll(/[^0-9;]+/g, "")
}

export function phoneStringToPhoneNumber(phoneString?: string) {
  return phoneString
    ? phoneString.split(";")
        .map(phoneNumber => `(+${phoneNumber.substring(0,2)}) ${phoneNumber.substring(2, 5)} ${phoneNumber.substring(5)}`)
    : []
}